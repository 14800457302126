/* eslint-disable import/prefer-default-export */
export function authHeader() {
  let store = JSON.parse(localStorage.getItem('persist:extranet'));
  let login = JSON.parse(store.login);
  const { user } = login;
  if (store && user.token && user.user.user_id) {
    return { Authorization: `Bearer ${user.token}`, 'Content-Type': 'application/json', 'x-user-id': user.user.user_id };
  } else {
    return {};
  }
}
