import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import ability from '../conf/ability';

export const PrivateRoute = ({ component: Component, ...Routeprops }) => {
  const { permission } = Routeprops;
  if (ability.rules.length > 0) {
    return (
      <>
        {ability.can(permission || 'read', 'Extranet') ? (
          <Route
            {...Routeprops}
            render={(props) =>
              Routeprops.auth == true ? (
                <Component user={Routeprops.user} permission={permission} {...props} />
              ) : (
                <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
              )
            }
          />
        ) : (
          // TODO Hacer 503
          /*  <div>No tiene los Permisos para visitar esta Página.</div> */
          <Redirect to={{ pathname: '/login' }} />
        )}
      </>
    );
  }
  return <Redirect to={{ pathname: '/login' }} />;
};
