import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Alert from "../components/Alert";
import { withRouter } from "react-router-dom";
import { alertActions } from "../actions/alert-actions";
// Carga las Fuentes
import "../static/fonts/styles.css";
import "../styles/theme.styl";
// carga Css Principal (tema Stylus)
import { isEmpty } from "lodash";
import ability from "../conf/ability";
import Router from "../router";
const propTypes = {
  clearMessage: PropTypes.func.isRequired,
};

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  /*Legacy Code for Rules
   UNSAFE_componentWillMount() {
   if (ability.rules.length === 1 && this.props.rules) {
     ability.update([{ subject: "Extranet", actions: "read" }, ...this.props.rules]);
   }
   }
   compara Las props anteriores con las nuevas.
   UNSAFE_componentWillReceiveProps(newProps) {
     if (newProps.location.pathname !== this.props.location.pathname) {
       this.props.clearNavs();
     }
   }
  */
  static getDerivedStateFromProps(nextProps, prevState) {
    if (ability.rules.length === 1 && nextProps.rules) {
      ability.update([{ subject: "Extranet", actions: "read" }, ...nextProps.rules]);
    }
    return null;
  }
  /* componentDidUpdate Verifica que todos los hijos ya se hayan Actualizado */
  componentDidUpdate(prevProps, prevState) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      if (!isEmpty(this.props.alert)) {
        this.props.clearMessage();
      }
    }
  }

  render() {
    const { alert, isAuth, username } = this.props;
    return (
      <React.Fragment>
        {alert.message && <Alert animation message={alert.message} type={alert.type} clearMessage={this.props.clearMessage} />}
        <Router isAuth={isAuth} user = {username} />
      </React.Fragment>
    );
  }
}
App.propTypes = propTypes;
// App.contextType = AppContext;
const mapStateToProps = (state) => {
  const { login, alert } = state;
  return {
    username: login.user.user,
    rules: login.user.permissions,
    isAuth: login.loggedIn,
    alert,
  };
};
const mapDispatchToProps = (dispatch) => ({
  clearMessage() {
    dispatch(alertActions.clear());
  },
});
const connectedApp = connect(mapStateToProps, mapDispatchToProps)(App);
const appWithRouter = withRouter(connectedApp);
export { appWithRouter as App };
