// import { CookieStorage } from "redux-persist-cookie-storage";
import storage from "redux-persist/lib/storage";
import Cookies from "cookies-js";
import { persistCombineReducers } from "redux-persist";
import login from "./login-reducer";
import alert from "./alert-reducer";
import navigation from "./nav-reducers";

const config = {
  key: "extranet",
  storage,
  whitelist: ["login"]
};
const rootReducer = persistCombineReducers(config, {
  login: login,
  alert,
  navigation
});

export default rootReducer;
