import React from "react";
import { render } from "react-dom";
import { Provider } from "react-redux";
import { Router } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import { ConfigProvider } from "antd";
// Nota: En produccion Habilitar El idioma en Webpack
import es_Es from "antd/lib/locale/es_ES";
// import es_Es from 'antd/es/locale/es_ES';
import moment from "moment";
import { App } from "./pages/App";
import store, { persistor } from "./store/store";
import { history } from "./store/history";
import "moment/locale/es";
import ability from "./conf/ability";

window.ability = ability;

moment.locale("es");
render(
  <PersistGate loading={null} persistor={persistor}>
    <Provider store={store}>
      <Router history={history}>
        <ConfigProvider locale={es_Es}>
          <App />
        </ConfigProvider>
      </Router>
    </Provider>
  </PersistGate>,
  document.getElementById("root")
);
if (module.hot) {
  module.hot.accept();
}
