import React from 'react';
import { Formik } from 'formik';
import { Spin } from 'antd';
// import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import * as Yup from 'yup';
import DisplayBookingForm from '../../components/LoginForm';
import { userActions } from '../../actions/user-actions';
import logo from '../../static/img/logos/logo-vertical.png';
import './styles.styl';

const initialValues = {
  user: 'admin@oasis.test',
  password: '',
};
const SignupSchema = Yup.object().shape({
  user: Yup.string().required('Requerido'),
  password: Yup.string().required('Requerido'),
});
class LoginPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: '',
      password: '',
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    const { dispatch } = this.props;
    if (this.props.loggedIn) dispatch(userActions.logout());
  }

  handleSubmit(formProps, actions) {
    const { user, password } = formProps;
    const { dispatch } = this.props;
    dispatch(userActions.login(user, password)).catch((e) => {
      const { error } = e;
      actions.setStatus(error);
      actions.setSubmitting(false);
    });
  }
  render() {
    const { loggingIn, loggedIn } = this.props;

    return (
      <div className="login">
        <div className="login__aside">&nbsp;</div>
        <div className="login__body">
          <Spin spinning={loggingIn} tip="Cargando...">
            <div className="login__brand">
              <img src={logo} alt="Caribe Maya" />
            </div>
            <div className="login__form">
              <p className="login__form__title">Acceso al Sistema</p>
              <Formik
                initialValues={initialValues}
                validationSchema={SignupSchema}
                onSubmit={this.handleSubmit}
              >
                {(props) => {
                  return <DisplayBookingForm {...props} />;
                }}
              </Formik>
            </div>
          </Spin>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { loggingIn, loggedIn } = state.login;
  return {
    loggingIn,
    loggedIn,
  };
}
const connectedLoginPage = connect(mapStateToProps)(LoginPage);
export { connectedLoginPage as LoginPage };
