import Axios from 'axios';
import { API_URL_BACK, API_URL } from '../conf/configurationProd';
import { history } from '../store/history';
import store from '../store/store';
import { userActions } from '../actions/user-actions';
import { authHeader } from './utils/authHeader.js';

function login(username, password) {
  return Axios.post(
    `${API_URL}/login/extranet`,
    { email: username, password },
    {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    }
  )
    .then((response) => {
      console.log(response);
      return response;
    })
    .catch((error) => {
      if (error.response) {
        return Promise.reject(error.response.data);
      }
      return Promise.reject(error.message);
    });
}

function comisionsUsersXChannelList(data) {
  const requestOptions = {
    headers: authHeader(),
  };
  return Axios.post(`${API_URL_BACK}tarifas/zona/edit/private/reps/list`, data, requestOptions)
    .then((response) => response)
    .catch((error) => handleResponse(error));
}

function comisionsUsersXChannel(data) {
  const requestOptions = {
    headers: authHeader(),
  };
  return Axios.post(`${API_URL_BACK}tarifas/zona/edit/private/reps`, data, requestOptions)
    .then((response) => response)
    .catch((error) => handleResponse(error));
}

function comisionsUsersXChannelUpdate(data) {
  const requestOptions = {
    headers: authHeader(),
  };
  return Axios.post(`${API_URL_BACK}tarifas/zona/edit/private/reps/update`, data, requestOptions)
    .then((response) => response)
    .catch((error) => handleResponse(error));
}

function comisionsUsersXChannelDelete(data) {
  const requestOptions = {
    headers: authHeader(),
  };
  return Axios.post(`${API_URL_BACK}tarifas/zona/edit/private/reps/delete`, data, requestOptions)
    .then((response) => response)
    .catch((error) => handleResponse(error));
}

function users() {
  return Axios.get(`${API_URL_BACK}usuarios/list`, {
    headers: authHeader(),
  })
    .then((response) => response)
    .catch((error) => handleResponse(error));
}

function usersCanales() {
  return Axios.get(`${API_URL_BACK}usuarios/canales`, {
    headers: authHeader(),
  })
    .then((response) => response)
    .catch((error) => handleResponse(error));
}

function getHotel(id) {
  return Axios.get(`${API_URL_BACK}usuarios/hoteles/${id}`, {
    headers: authHeader(),
  })
    .then((response) => response)
    .catch((error) => handleResponse(error));
}

function getLocation(id) {
  return Axios.get(`${API_URL_BACK}usuarios/locacion/${id}`, {
    headers: authHeader(),
  })
    .then((response) => response)
    .catch((error) => handleResponse(error));
}

function addSalesPlaces(data) {
  const requestOptions = {
    headers: authHeader(),
  };
  return Axios.post(`${API_URL_BACK}usuario/sales-places/agregar`, data, requestOptions)
    .then((response) => response)
    .catch((error) => handleResponse(error));
}

function deleteSalesPlaces(data) {
  const requestOptions = {
    headers: authHeader(),
  };
  return Axios.post(`${API_URL_BACK}usuarios/sales/eliminar`, data, requestOptions)
    .then((response) => response)
    .catch((error) => handleResponse(error));
}

function agregarSalesLocation(data) {
  const requestOptions = {
    headers: authHeader(),
  };
  return Axios.post(`${API_URL_BACK}usuarios/sales-location/agregar`, data, requestOptions)
    .then((response) => response)
    .catch((error) => handleResponse(error));
}

function editarSalesLocation(data) {
  const requestOptions = {
    headers: authHeader(),
  };
  return Axios.post(`${API_URL_BACK}usuarios/sales-location/editar`, data, requestOptions)
    .then((response) => response)
    .catch((error) => handleResponse(error));
}

function eliminarSalesLocation(data) {
  const requestOptions = {
    headers: authHeader(),
  };
  return Axios.post(`${API_URL_BACK}usuarios/sales-location/eliminar`, data, requestOptions)
    .then((response) => response)
    .catch((error) => handleResponse(error));
}

function statusSalesPlaces(data) {
  const requestOptions = {
    headers: authHeader(),
  };
  return Axios.post(`${API_URL_BACK}usuarios/sales-place/status`, data, requestOptions)
    .then((response) => response)
    .catch((error) => handleResponse(error));
}

function productos() {
  return Axios.get(`${API_URL_BACK}usuario/tipoventa`, {
    headers: authHeader(),
  })
    .then((response) => response)
    .catch((error) => handleResponse(error));
}

function canales() {
  return Axios.get(`${API_URL_BACK}canales`, {
    headers: authHeader(),
  })
    .then((response) => response)
    .catch((error) => handleResponse(error));
}

function agregarCanal(data) {
  const requestOptions = {
    headers: authHeader(),
  };
  return Axios.post(`${API_URL_BACK}usuarios/canales`, data, requestOptions)
    .then((response) => response)
    .catch((error) => handleResponse(error));
}

function subProducto(id) {
  return Axios.get(`${API_URL_BACK}subproducto/${id}`, {
    headers: authHeader(),
  })
    .then((response) => response)
    .catch((error) => handleResponse(error));
}

function services(id) {
  return Axios.get(`${API_URL_BACK}subproducto/services/${id}`, {
    headers: authHeader(),
  })
    .then((response) => response)
    .catch((error) => handleResponse(error));
}

// function getAll() {
//   const requestOptions = {
//     method: 'GET',
//     // debe concatenar el header
//     headers: authHeader(),
//   };
//   return fetch(`${apiUrl}users?page=2`, requestOptions).then(handleResponse);
// }

// function getById(id) {
//   const requestOptions = {
//     method: 'GET',
//     headers: authHeader(),
//   };

//   return fetch(`${apiUrl}/users/${id}`, requestOptions).then(handleResponse);
// }

function crearUsuario(data) {
  const requestOptions = {
    headers: authHeader(),
  };
  return Axios.post(`${API_URL_BACK}crear/usuario`, data, requestOptions)
    .then((response) => response)
    .catch((error) => handleResponse(error));
}

function editarUsuario(id) {
  return Axios.get(`${API_URL_BACK}usuario/editar/${id}`, {
    headers: authHeader(),
  })
    .then((response) => response)
    .catch((error) => handleResponse(error));
}

function updateUsuario(data) {
  const requestOptions = {
    headers: authHeader(),
  };
  return Axios.post(`${API_URL_BACK}update/usuario`, data, requestOptions)
    .then((response) => response)
    .catch((error) => handleResponse(error));
}

function usersStatus(id) {
  return Axios.get(`${API_URL_BACK}usuario/status/${id}`, {
    headers: authHeader(),
  })
    .then((response) => response)
    .catch((error) => handleResponse(error));
}

function usersDelete(id) {
  return Axios.get(`${API_URL_BACK}usuario/delete/${id}`, {
    headers: authHeader(),
  })
    .then((response) => response)
    .catch((error) => handleResponse(error));
}

function roles() {
  return Axios.get(`${API_URL_BACK}roles/usuarios`, {
    headers: authHeader(),
  })
    .then((response) => response)
    .catch((error) => handleResponse(error));
}

// function update(user, token) {
//   const requestOptions = {
//     method: 'PUT',
//     headers: { ...authHeader(), 'Content-Type': 'application/json' },
//     body: JSON.stringify(user),
//   };

//   return fetch(`${apiUrl}/users/${user.id}`, requestOptions).then(handleResponse);
// }

function proveedores() {
  return Axios.get(`${API_URL_BACK}lista/tour/proveedores`, {
    headers: authHeader(),
  })
    .then((response) => response)
    .catch((error) => handleResponse(error));
}

const usersList = async () => {
  return await Axios.get(`${API_URL_BACK}users/list`, {
    headers: authHeader(),
  })
    .then((response) => response)
    .catch((error) => handleResponse(error));
};

function handleResponse(error) {
  if (error.response) {
    if (error.response.status === 401) {
      store.dispatch(userActions.logout());
      history.push('/');
    }
    return Promise.reject(error.response.data);
  }
  if (error.request) {
    return Promise.reject(error.request);
  }
  return Promise.reject(error.message);
}
function copiarProductos(data) {
  const requestOptions = {
    headers: authHeader(),
  };
  return Axios.post(`${API_URL_BACK}replicar-productos`, data, requestOptions)
    .then((response) => response)
    .catch((error) => handleResponse(error));
}
function usersAgency() {
  const requestOptions = {
    headers: authHeader(),
  };
  return Axios.get(`${API_URL_BACK}usuarios-agencia`, requestOptions)
    .then((response) => response)
    .catch((error) => handleResponse(error));
}
export const userService = {
  comisionsUsersXChannelDelete,
  comisionsUsersXChannelList,
  comisionsUsersXChannel,
  comisionsUsersXChannelUpdate,
  login,
  users,
  productos,
  canales,
  usersCanales,
  crearUsuario,
  usersStatus,
  usersDelete,
  editarUsuario,
  updateUsuario,
  roles,
  proveedores,
  subProducto,
  services,
  getHotel,
  addSalesPlaces,
  deleteSalesPlaces,
  getLocation,
  agregarSalesLocation,
  eliminarSalesLocation,
  editarSalesLocation,
  statusSalesPlaces,
  agregarCanal,
  usersList,
  copiarProductos,
  usersAgency
};
