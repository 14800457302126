import React from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

const withPermissions = (WrappedComponent) => {
    const Wrapper = (props) => {
        const { permissions, location } = props;

        // Check if the current route is allowed for that user.
        const isAllowed = permissions
            .includes(location.pathname);

        if (!isAllowed) {
            if (!isAllowed) {
                return <Redirect to="/welcome" />;
            }
        }

        return <WrappedComponent {...props} />;
    };

    const mapStateToProps = (state) => {
        const { login } = state;
        const { user } = login;
        const { permissions } = user;

        return {
            user,
            permissions,
        };
    };

    return connect(mapStateToProps)(Wrapper);
};

export default withPermissions;
