import React from 'react';
import { DatePicker, Form, Input, TimePicker, Select, Checkbox, InputNumber } from 'antd';
import FormGroup from '../WrapInputs/Wrapper';
import { isFunction, get } from 'lodash';
const FormItem = Form.Item;
const { Option } = Select;

const { TextArea } = Input;
const CreateAntField =
  (AntComponent) =>
  ({
    field,
    form,
    hasFeedback,
    label,
    selectOptions,
    submitCount,
    type,
    noMargin,
    validador,
    validadorCanal,
    selectTipo,
    validateNumber,
    valiteTipoReporte,
    producto,
    callback,
    ...props
  }) => {
    const touched = get(form.touched, field.name);
    const submitted = submitCount > 0;
    const hasError = get(form.errors, field.name);
    const submittedError = hasError && submitted;
    const touchedError = hasError && touched;
    const onInputChange = ({ target: { value } }) => {
      // console.log(field.name, value);
      if (callback) {
        callback(value);
      }
      form.setFieldValue(field.name, value);
    };
    const onChange = (value, option) => {
      if (validador) {
        validador(value);
      }
      if (validadorCanal) {
        validadorCanal(value);
      }
      if (selectTipo) {
        selectTipo(value);
      }
      if (valiteTipoReporte) {
        valiteTipoReporte(value);
      }
      if (producto) {
        producto(value);
      }
      if (callback) {
        callback(value, option);
      }
      form.setFieldValue(field.name, value);
    };
    const onBlur = (value) => {
      form.setFieldTouched(field.name, true);
    };

    return (
      <FormGroup label={label} noMargin={noMargin}>
        <FormItem
          // label={label}
          style={{ margin: 0 }}
          hasFeedback={!!((hasFeedback && submitted) || (hasFeedback && touched))}
          help={submittedError || touchedError ? hasError : false}
          validateStatus={submittedError || touchedError ? 'error' : 'success'}
        >
          {type == 'password' ? (
            <AntComponent.Password
              {...field}
              {...props}
              onBlur={onBlur}
              onChange={type ? onInputChange : onChange}
            />
          ) : (
            <AntComponent
              {...field}
              {...props}
              onBlur={onBlur}
              onChange={type ? onInputChange : onChange}
            >
              {selectOptions
                ? isFunction(selectOptions)
                  ? selectOptions()
                  : selectOptions.map((item) => {
                      if (typeof item === 'object') {
                        return (
                          <Option label={item.name} key={item.id} value={item.id}>
                            {item.name}
                          </Option>
                        );
                      } else {
                        return (
                          <Option label={item} key={item}>
                            {item}
                          </Option>
                        );
                      }
                    })
                : null}
            </AntComponent>
          )}
        </FormItem>
      </FormGroup>
    );
  };
const CreateCheckBox =
  (AntComponent) =>
  ({ field, form, hasFeedback, label, submitCount, ...props }) => {
    const touched = form.touched[field.name];
    const submitted = submitCount > 0;
    const hasError = form.errors[field.name];
    const submittedError = hasError && submitted;
    const touchedError = hasError && touched;
    const onInputChange = ({ target: { checked } }) => {
      form.setFieldValue(field.name, checked);
    };
    return (
      <div>
        <FormItem
          hasFeedback={!!((hasFeedback && submitted) || (hasFeedback && touched))}
          help={submittedError || touchedError ? hasError : false}
        >
          <AntComponent {...field} {...props} onChange={onInputChange}>
            {label}
          </AntComponent>
        </FormItem>
      </div>
    );
  };
export const AntSelect = CreateAntField(Select);
export const AntDatePicker = CreateAntField(DatePicker);
export const AntInput = CreateAntField(Input);
export const AntInputNumber = CreateAntField(InputNumber);
export const AntTimePicker = CreateAntField(TimePicker);
export const AntCheckBox = CreateCheckBox(Checkbox);
export const AntTextArea = CreateAntField(TextArea);
