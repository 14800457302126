import { Breadcrumb } from 'antd';
import { navTypes } from '../constants';

const initialState = { navData: { payload: {}, breadcrumb: [] } };
const navigation = (state = initialState, action) => {
  switch (action.type) {
    case navTypes.CHANGE_TITLE:
      console.log(action.payload);
      if (action.payload.breadcrumb) {
        const { breadcrumb, ...payload } = action.payload;
        return {
          ...state,
          navData: {
            ...state.navData,
            payload,
            breadcrumb,
          },
        };
      }
      return {
        ...state,
        navData: { ...state.navData, payload: { ...action.payload } },
      };

    case navTypes.CLEAR_TITLE: // remueve tanto el titulo como el breadcrumb.
      return initialState;
    default:
      return state;
  }
};
export default navigation;
