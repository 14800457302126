import { loginTypes } from "../constants";

const INITIAL_STATE = { loggingIn: false, loggedIn: false, user: { roles: [] } };
const login = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case loginTypes.LOGIN_REQUEST:
      return {
        loggingIn: true,
        user: action.user
      };
    case loginTypes.LOGIN_SUCCESS:
      console.log(action);
      return {
        user: action.payload,
        loggedIn: true,
        loggingIn: false
      };
    case loginTypes.LOGIN_ERROR:
      console.log("LOGGIN ERROR");
      return INITIAL_STATE;
    case loginTypes.LOGOUT:
      console.log("LOGGIN LOGOUT");
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default login;
