export * from './login-types';
export * from './navigation-types.js';
export * from './alert-types';

const calendarTemplate = {
  Sunday: { dia: 'Domingo', key: 'Sunday', horarios: [] },
  Monday: { dia: 'Lunes', key: 'Monday', horarios: [] },
  Tuesday: { dia: 'Martes', key: 'Tuesday', horarios: [] },
  Wednesday: { dia: 'Miércoles', key: 'Wednesday', horarios: [] },
  Thursday: { dia: 'Jueves', key: 'Thursday', horarios: [] },
  Friday: { dia: 'Viernes', key: 'Friday', horarios: [] },
  Saturday: { dia: 'Sábado', key: 'Saturday', horarios: [] },
};

export { calendarTemplate };
