import { createStore, applyMiddleware } from "redux";
import thunkMiddleware from "redux-thunk";
import { createLogger } from "redux-logger";
import { persistStore } from "redux-persist";
import rootReducer from "../reducers";
// removemos logs en producción
const loggerMiddleware = createLogger({
  predicate: (getState, action) => process.env.NODE_ENV === "development"
});
const store = createStore(rootReducer, applyMiddleware(thunkMiddleware, loggerMiddleware));
export const persistor = persistStore(store);
export default store;
