import React, { Component } from 'react';
import { Alert } from 'antd';
import css from './styles.styl'
class Alerta extends Component {
  constructor(props) {
    super(props);
    this.state = { visible: true };
    this.handleClose = this.handleClose.bind(this);
  }

  handleClose() {
    this.setState({ visible: false });
    this.props.clearMessage();
  }

  render() {
    const { animation, ...props } = this.props
    return (
      <div>
        {this.state.visible ? (
          <Alert
            {...props}
            className={`${css.alert_custom} ${animation ? css['alert_custom--animate'] : ''}`}
            closable
            afterClose={this.handleClose}
          />
        ) : null}
      </div>
    );
  }
}

export default Alerta;
