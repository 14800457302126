import React from "react";
import "./index.styl";

const FormGroup = ({ label, ...props }) => (
  <div className={`${props.noMargin ? `form__group no-margin` : "form__group"}`}>
    <div className="form__group__label">{label}</div>
    {props.children}
  </div>
);

export default FormGroup;
